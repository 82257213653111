import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, withRouter, Route } from "react-router-dom";
import Loadable from "react-loadable";

import MainLoadingComponent from "components/mainLoading";
import { IRouterProps } from "routes/IRouterProps";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

const AsyncSignup: any = Loadable({
  loader: () => import("containers/signup"),
  loading: MainLoadingComponent
});

const AsyncLogin: any = Loadable({
  loader: () => import("containers/login"),
  loading: MainLoadingComponent
});
const AsyncHomeAcc: any = Loadable({
  loader: () => import("containers/homeAcc"),
  loading: MainLoadingComponent
});

const AsyncHome: any = Loadable({
  loader: () => import("containers/home"),
  loading: MainLoadingComponent
});

const AsyncResetPasswordCode: any = Loadable({
  loader: () => import("containers/resetPasswordCode"),
  loading: MainLoadingComponent
});

const AsyncForgotPassword: any = Loadable({
  loader: () => import("containers/forgotPassword"),
  loading: MainLoadingComponent
});

const AsyncCreateGroup: any = Loadable({
  loader: () => import("containers/createGroup"),
  loading: MainLoadingComponent
});

const AsyncGroupView: any = Loadable({
  loader: () => import("containers/groupView"),
  loading: MainLoadingComponent
});

const AsyncGroupMembersPublic: any = Loadable({
  loader: () => import("containers/groupMembersPublic"),
  loading: MainLoadingComponent
});

const AsyncGroupSettings: any = Loadable({
  loader: () => import("containers/groupSettings"),
  loading: MainLoadingComponent
});
const AsyncSearch: any = Loadable({
  loader: () => import("containers/search"),
  loading: MainLoadingComponent
});
const AsyncAbout: any = Loadable({
  loader: () => import("containers/userSettings"),
  loading: MainLoadingComponent
});

const AsyncNotifications: any = Loadable({
  loader: () => import("containers/notifications"),
  loading: MainLoadingComponent
});

const AsyncSinglePost: any = Loadable({
  loader: () => import("containers/singlePost"),
  loading: MainLoadingComponent
});

export class MainRouter extends Component<IRouterProps, any> {
  public render() {
    const { subdomain, isAuthed, user } = this.props;

    return !subdomain ? (
      <Switch>
        <PublicRoute exact={true} path="/signup" component={<AsyncSignup />} />
        <PublicRoute
          authed={isAuthed}
          exact={true}
          path="/"
          component={<AsyncHomeAcc />}
        />
        <Route
          exact={true}
          path="/reset-password"
          component={AsyncResetPasswordCode}
        />
        <Route
          exact={true}
          path="/forgot-password"
          component={AsyncForgotPassword}
        />

        <PublicRoute
          authed={isAuthed}
          exact={true}
          path="/login"
          component={<AsyncLogin />}
        />
        <PrivateRoute
          authed={isAuthed}
          exact={true}
          path={"/home"}
          component={<AsyncHome />}
        />
        <PrivateRoute
          authed={isAuthed}
          exact={true}
          user={user}
          path={"/creategroup"}
          component={<AsyncCreateGroup />}
        />
        <PublicRoute
          exact={true}
          path="/group/:groupSlug?"
          component={<AsyncGroupView />}
        />
        <PublicRoute
          exact={true}
          path="/posts/:id"
          component={<AsyncSinglePost />}
        />
        <PublicRoute
          exact={true}
          path="/channel/:groupSlug/:channelId"
          component={<AsyncGroupView />}
        />
        <Route
          authed={isAuthed}
          group={true}
          exact={true}
          path={"/group/:groupSlug/members"}
          component={AsyncGroupMembersPublic}
        />
        <Route
          exact={false}
          path="/group/:groupSlug?/settings"
          component={AsyncGroupSettings}
        />
        <PrivateRoute
          authed={isAuthed}
          exact={true}
          path={"/search"}
          component={<AsyncSearch />}
        />
        <PrivateRoute
          authed={isAuthed}
          exact={true}
          path={"/notifications"}
          component={<AsyncNotifications />}
        />
        <PrivateRoute
          authed={isAuthed}
          exact={true}
          path={"/about"}
          component={<AsyncAbout />}
        />
      </Switch>
    ) : (
      ""
    );
  }
}

// @ts-ignore
export default withRouter<any>(
  connect(
    null,
    null
  )(MainRouter as any)
) as typeof MainRouter;
