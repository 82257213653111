import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { IRoute } from "./IRoute";
import { Map } from "immutable";

export class PublicRoute extends Component<IRoute, any> {
  public render() {
    const { authed, path, component, disableRedirect } = this.props;
    return (
      <Route
        path={path}
        render={() => {
          return authed && !disableRedirect ? <Redirect to="/home"/> : (() => component)();
        }}
      />
    );
  }
}

const mapStateToProps = (state: Map<string, any>, nextProps: IRoute) => {
  return {
    authed: state.getIn(["authorize", "authed", false])
  };
};

export default connect(mapStateToProps)(PublicRoute as any);
