import React, { Component } from "react";
import { Route } from "react-router-dom";
import { IRoute } from "./IRoute";
import { connect } from "react-redux";
import { Map } from "immutable";
import LoginComponent from "../containers/login/LoginComponent";

export class PrivateRoute extends Component<IRoute, any> {
  public render() {
    const { authed, path, component, exact } = this.props;
    return (
      <Route
        isExact={exact}
        path={path}
        render={() => {
          return authed ? (() => component)() : <LoginComponent />;
        }}
      />
    );
  }
}

const mapStateToProps = (state: Map<string, any>, nextProps: IRoute) => {
  return {
    authed: state.getIn(["authorize", "authed"])
  };
};

export default connect(mapStateToProps)(PrivateRoute as any);
