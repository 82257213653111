export class LoginUser {
  constructor(
    private _uid: string,
    private _displayName: string = "",
    private _email: string = "",
    private _avatarURL: string = ""
  ) {}

  public get uid(): string {
    return this._uid;
  }

  public get displayName(): string {
    return this._displayName;
  }

  public get email(): string {
    return this._email;
  }

  public get avatarURL(): string {
    return this._avatarURL;
  }
}
