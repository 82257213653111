import { AuthorizeActionType } from "../../../constants/authorizeActionType";
import { AuthorizeState } from "./AuthorizeState";
import { IAuthorizeAction } from "./IAuthorizeAction";
import { Map } from "immutable";

export let authorizeReducer = (
  // @ts-ignore
  state = Map(new AuthorizeState()),
  action: IAuthorizeAction
) => {
  const { payload } = action;
  switch (action.type) {
    case AuthorizeActionType.LOGIN:
      return state
        .set("uid", payload.uid)
        .set("user", payload.user)
        .set("authed", true)
        .set("guest", false)
        .set("isVerifide", payload.isVerifide);

    case AuthorizeActionType.LOGOUT:
      return state
        .set("uid", 0)
        .set("user", null)
        .set("authed", false)
        .set("guest", true)
        .set("isVerifide", false);
    case AuthorizeActionType.SIGNUP:
      return state.set("uid", payload.id);
    case AuthorizeActionType.UPDATE_PASSWORD:
      return state.set("updatePassword", payload.updatePassword);
    default:
      return state;
  }
};
