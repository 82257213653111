export class User {
  /**
   * Full name of user
   *
   * @type {string}
   * @memberof User
   */
  public full_name: string = "";
  public avatar: string = "";
  public email?: string | null;
  public password?: string | null;
  public id?: number | null;
  public creationDate: number = -1;
}
