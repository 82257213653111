import { SocialError } from "../../core/models/common";
import { globalActions, groupActions } from "./index";
import { provider } from "../../groupApp";
import { SocialProviderTypes } from "../../core/socialProviderTypes";
import { IGroupService } from "../../core/services/groups";
import { GroupActionType } from "../../constants/groupActionType";
import { push } from "connected-react-router";

export const groupService: IGroupService = provider.get<IGroupService>(
  SocialProviderTypes.GroupService
);

export const dbCreate = (
  userId: number,
  name: string,
  description: string,
  status: string,
  slug?: string
) => {
  return (dispatch: any, getState: any) => {
    return groupService
      .createGroup(userId, name, description, status, slug)
      .then(
        (result: any) => {
          if (userId) {
            dispatch(groupActions.dbGetUserGroups(userId));
          }
          dispatch(push(`/group/${result.data.slug}`));
        },
        (error: SocialError) => {
          dispatch(globalActions.showMessage(error.code));
        }
      );
  };
};

export const dbGetAll = (searchQuery?: any) => {
  return (dispatch: any, getState: any) => {
    return groupService.getAllGroups(searchQuery).then(
      (result: any) => {
        dispatch(globalActions.hideTopLoading());
      },
      (error: SocialError) => {
        dispatch(globalActions.showMessage(error.code));
      }
    );
  };
};

export const getUserGroups = (groups: any) => {
  return {
    type: GroupActionType.GET,
    payload: { groups }
  };
};

export const setSidebarLoadingStatus = (state: boolean) => {
  return {
    type: GroupActionType.LOAD,
    state
  };
};

export const dbGetUserGroups = (userId: number, isGroupRemoved?: boolean) => {
  return (dispatch: any, getState: any) => {
    dispatch(setSidebarLoadingStatus(true));
    if (userId) {
      return groupService.getUserGroups(userId).then(
        (result: any) => {
          if (result && result.data) {
            dispatch(getUserGroups(result.data));
            dispatch(setSidebarLoadingStatus(false));
            if (isGroupRemoved) {
              dispatch(push(`/`));
            }
          }
        },
        (error: SocialError) => {
          dispatch(setSidebarLoadingStatus(false));
          dispatch(globalActions.showMessage(error.code));
        }
      );
    }
  };
};

export const dbGetPostData = (postId: number) => {
  return (dispatch: any, getState: any) => {
    dispatch(setSidebarLoadingStatus(true));
    if (postId) {
      return groupService.getGroupPost(postId).then(
        (result: any) => {
          if (result) {
            dispatch(getPostData(result));
            dispatch(setSidebarLoadingStatus(false));
          }
        },
        (error: SocialError) => {
          dispatch(setSidebarLoadingStatus(false));
          dispatch(globalActions.showMessage(error.code));
        }
      );
    }
  };
};
export const getPostData = (post: any) => {
  return {
    type: GroupActionType.POST,
    payload: post
  };
};

export const dbGroupJoin = (userId: any, groupId: any) => {
  return (dispatch: any, getState: any) => {
    return groupService.joinGroup(userId, groupId).then((result: any) => {
      dispatch(groupActions.dbGetUserGroups(userId));
    });
  };
};

export const dbGroupLeave = (userId: any, groupId: any) => {
  return (dispatch: any, getState: any) => {
    return groupService.leaveGroup(userId, groupId).then((result: any) => {
      dispatch(groupActions.dbGetUserGroups(userId));
    });
  };
};
