import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { ITopBarComponentsProps } from "./ITopBarComponentsProps";
import { globalActions } from "../../store/actions";
import "./TopBarComponent.scss";
import { Map } from "immutable";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faChevronDown, faCog } from "@fortawesome/free-solid-svg-icons";
import { ITopBarComponentState } from "./ITopBarComponentState";
import { GroupService } from "../../api/services/groups";
import miscUtils from "../../utils/miscUtils";
import { push } from "connected-react-router";
import { SearchIcon } from "../../constants/icons";
import useComponentVisible from "../../utils/visible/visible";
import { Link } from "react-router-dom";
import TopBarMenuComponent from "./topBarMenu";
import TopBarNotificationComponent from "./topBarNotification";

const TopBarComponent: React.FC<ITopBarComponentsProps> = (props: any) => {
  // @ts-ignore
  const {
    toggleHamburgerMenu,
    goTo,
    authed,
    global,
    showPublicGroupLogin,
    showPublicGroupSignup
  } = props;

  const [results, setResults] = useState<ITopBarComponentState>([]);
  // @ts-ignore
  const [countResults, setCountResults] = useState<ITopBarComponentState>(0);
  // @ts-ignore
  const [search, setSearch] = useState<ITopBarComponentState>("");
  const [blockedGroups, setBlockedGroups] = useState<any>([]);

  library.add(faBell);
  library.add(faCog);
  library.add(faChevronDown);

  // @ts-ignore
  const searchParama = props.location.search;
  // @ts-ignore
  const params = new URLSearchParams(searchParama);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  const onHamburgerToggle = () => {
    toggleHamburgerMenu();
  };

  useEffect(() => {
    if (props.userGroups && props.userGroups.length) {
      const blockedGroups: any = [];
      props.userGroups.forEach((userGroup: any) => {
        if (userGroup.blocked) {
          blockedGroups.push(userGroup.group.id);
        }
      });
      setBlockedGroups(blockedGroups);
    }
  }, [props.userGroups]);

  useEffect(() => {
    setSearch(props.currentView);
  }, [props.currentView]);

  function searchGroups(searchQuery: string) {
    setSearch(searchQuery);
    if (searchQuery.length > 0) {
      GroupService.getInstance()
        .getAllGroups(searchQuery)
        .then((response: any) => {
          if (response && response.data) {
            if (blockedGroups.length) {
              response.data = response.data.filter((groupResult: any) => {
                return blockedGroups.indexOf(groupResult.id) < 0;
              });
            }

            setResults(response.data);
            setCountResults(response.data.length);
          }
        });
    } else {
      setResults([]);
    }
  }

  function openGroup(groupSlug: string) {
    setResults([]);
    goTo(`/group/${groupSlug}`);
  }

  function handleConfirm(e: any) {
    if (e.key === "Enter" && search && search.length > 0) {
      setIsComponentVisible(false);
      goTo(`/search/?q=${search}`);
    }
  }

  return (
    <header className={"g-header"}>
      <div
        className={
          !authed
            ? "header-wrap flex flex-align-center no-auth"
            : "header-wrap flex flex-align-center"
        }
      >
        <div className="header-action-left flex-align-center flex">
          {authed && (
            <div className="header-action flex flex-justify-center flex-align-center">
              <button
                className={
                  global.toggleSidebar
                    ? "flex-align-center flex-direction-column flex is-sidebar-open"
                    : "flex-align-center flex-direction-column flex"
                }
                onClick={onHamburgerToggle}
              >
                <span />
                <span />
                <span />
              </button>
            </div>
          )}
          <div
            onClick={() => {
              goTo("/");
            }}
            className="header-logo"
          >
            <h1>G</h1>
          </div>
          {authed && (
            <div className="header-search" ref={ref}>
              <input
                onKeyDown={handleConfirm}
                value={search}
                onChange={e => searchGroups(e.target.value)}
                placeholder="Search for groups"
                onClick={() => setIsComponentVisible(true)}
              />
              <SearchIcon />

              {results &&
                (isComponentVisible && (
                  <div className="search-results">
                    <div>
                      {results.slice(0, 5).map((result: any) => {
                        return (
                          <div
                            key={result.id}
                            onClick={() => {
                              openGroup(result.slug);
                            }}
                            className="search-result flex flex-justify-space-between"
                          >
                            <div className="search-result-group-info flex flex-align-center">
                              {result.profile_image ? (
                                <img
                                  alt={result.name}
                                  className="profile-image"
                                  src={result.profile_image}
                                />
                              ) : (
                                <div className="profile-image">
                                  {miscUtils.getNameInitials(result.name)}
                                </div>
                              )}
                              <div className="group-name">{result.name}</div>
                            </div>

                            <div className="group-status">
                              {result.status.toLowerCase()} group
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {countResults > 5 && (
                      <div className="search-more-results flex flex-align-center flex-justify-center">
                        <Link
                          onClick={() => setIsComponentVisible(false)}
                          to={{
                            pathname: "/search",
                            search: `?q=${search}`
                          }}
                        >
                          View All Results
                        </Link>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="header-action-right flex-align-center flex">
          {authed && (
            <button
              onClick={() => {
                props.createGroup();
              }}
              className="header-create-group"
            >
              Create a group
            </button>
          )}
          {authed && <TopBarNotificationComponent />}
          {authed && <TopBarMenuComponent />}
          {!authed && !search && (
            <div className="header-menu">
              <ul>
                <li>Help</li>
              </ul>
            </div>
          )}
          {!authed && search && search.length > 0 && !params.get("invite") && (
            <div className="header-menu public-group-menu flex">
              <button
                onClick={() => showPublicGroupLogin()}
                className="btn btn-no-style btn-header-login"
                type="button"
              >
                Login
              </button>
              <button
                onClick={() => showPublicGroupSignup()}
                className="btn btn-no-style btn-header-signup"
                type="button"
              >
                Sign up
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

// - Map dispatch to props
const mapDispatchToProps = (
  dispatch: any,
  ownProps: ITopBarComponentsProps
) => {
  return {
    toggleHamburgerMenu: () => dispatch(globalActions.toggleHamburgerMenu()),
    showPublicGroupLogin: () => dispatch(globalActions.showPublicGroupLogin()),
    showPublicGroupSignup: () =>
      dispatch(globalActions.showPublicGroupSignup()),
    goTo: (url: string) => dispatch(push(url)),
    createGroup: () => {
      dispatch(push("/creategroup"));
    }
  };
};
const mapStateToProps = (state: any, ownProps: ITopBarComponentsProps) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const groups: any = Map(state.get("group", {})).toJS();
  return {
    global,
    currentView: global.currentView,
    userGroups: groups.userGroups
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBarComponent as any) as any) as any;
