import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IMainComponentState } from "./IMainComponentState";
import { IMainComponentProps } from "./IMainComponentProps";
import MainLoading from "../../components/mainLoading";
import { MainRouter } from "../../routes/MainRouter";
import { Map } from "immutable";
import {
  authorizeActions,
  globalActions,
  groupActions
} from "../../store/actions";
import { push } from "connected-react-router";
import TopBarComponent from "../../components/topBar";
import "./MainComponent.scss";
import { AuthorizeService } from "../../api/services/authorize";
import SidebarComponent from "../../components/sidebar";
import { clearMessage } from "../../store/actions/globalActions";
import useWindowSize from "../../utils/windowSize";
import { getCookieValue } from "../../utils/cookieStorage";
import appConfig from "../../appConfig";
import { SubdomainRouter } from "../../routes/SubdomainRouter";
import SidebarForChannelComponent from "../../components/sidebar/sidebarForChannel";

const MainComponent: React.FC<IMainComponentProps> = props => {
  const {
    global,
    isAuthed,
    logout,
    login,
    getUserGroups,
    hideTopLoading,
    globalUser
  } = props;

  const [currentRoute, setCurrentRoute] = useState<IMainComponentState>();
  const [isSideBar, setIsSideBar] = useState<any>("");
  const [user, setCurrentUser] = useState<any>();
  const [subdomain, setCurrentSubdomain] = useState<any>();

  const [width] = useWindowSize();
  // @ts-ignore
  const previousRoute = usePreviousRoute(currentRoute);

  useEffect(() => {
    AuthorizeService.getInstance().onAuthStateChanged(handleAuthStateChanges);
  }, [isAuthed]);

  useEffect(() => {
    setIsSideBar(getCookieValue("sidebar"));
  }, [getCookieValue("sidebar")]);

  useEffect(() => {
    setCurrentUser(globalUser);
  }, [globalUser]);

  useEffect(() => {
    setCurrentRoute(props.location.pathname);

    const [currentSubdomain] = window.location.hostname.split(".");

    if (
      currentSubdomain &&
      !appConfig.reservedSubdomains.includes(currentSubdomain)
    ) {
      setCurrentSubdomain(currentSubdomain);
    } else {
      setCurrentSubdomain(null);
    }
  }, [props.location.pathname]);

  function usePreviousRoute(value: any) {
    const ref = useRef();

    useEffect(() => {
      if (ref.current !== value) {
        clearMessage();
      }
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  function handleAuthStateChanges(user: any) {
    hideTopLoading!();

    if (user && user.user) {
      user = user.user;
    }

    if (user) {
      getUserGroups(user.id);
      setCurrentUser(user);
      login(user);
    } else {
      setCurrentUser(null);
    }
  }

  /**
   * Render app DOM component
   *
   * @returns
   *
   * @memberof Main
   */

  return (
    <div id="master">
      <TopBarComponent authed={isAuthed} logout={logout} />
      {global.showTopLoading ? (
        <MainLoading
          isLoading={global.showTopLoading}
          pastDelay={false}
          timedOut={false}
          retry={() => {}}
          error={null}
        />
      ) : null}
      <main id={"site-content"}>
        <div
          className={
            isSideBar && width > 890
              ? global.showChannelSidebar
                ? "main-wrap sidebar-is-open channel-preview"
                : global.showSidebarForChannel
                ? "main-wrap channel-sidebar-only"
                : "main-wrap sidebar-is-open"
              : global.showChannelSidebar
              ? "main-wrap channel-preview"
              : "main-wrap"
          }
        >
          {isAuthed && <SidebarComponent subdomain={subdomain} />}
          {global.showSidebarForChannel && !isAuthed && (
            <SidebarForChannelComponent subdomain={subdomain} />
          )}
          <div
            className={
              !isAuthed
                ? "content-wrap flex flex-justify-center no-auth"
                : "content-wrap flex flex-justify-center"
            }
          >
            <SubdomainRouter
              isLoading={global.showTopLoading}
              subdomain={subdomain}
              user={user}
              isAuthed={isAuthed}
            />
            <MainRouter
              isLoading={global.showTopLoading}
              subdomain={subdomain}
              user={user}
              isAuthed={isAuthed}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

// - Map dispatch to props
const mapDispatchToProps = (dispatch: any, ownProps: IMainComponentProps) => {
  return {
    login: (user: any) => {
      dispatch(authorizeActions.login(user));
    },
    logout: () => {
      dispatch(authorizeActions.dbLogout());
    },
    getUserGroups: (userId: any) => {
      if (userId) {
        dispatch(groupActions.dbGetUserGroups(userId));
      }
    },
    goTo: (url: string) => dispatch(push(url)),
    hideTopLoading: () => dispatch(globalActions.hideTopLoading()),
    showMasterLoading: () => dispatch(globalActions.showMasterLoading()),
    hideMasterLoading: () => dispatch(globalActions.hideMasterLoading()),
    clearMessage: () => dispatch(globalActions.clearMessage())
  };
};

const mapStateToProps = (state: Map<string, any>) => {
  const authorize = Map(state.get("authorize", {})).toJS() as any;
  const global = Map(state.get("global", {})).toJS() as any;
  const globalUser: any = state.getIn(["authorize", "user"]);
  const { sendFeedbackStatus, progress } = global;

  return {
    globalUser,
    global,
    sendFeedbackStatus,
    progress,
    guest: authorize.guest,
    uid: authorize.uid,
    isAuthed: authorize.authed
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent as any) as any);
