import moment from "moment/moment";

const logger = (title: string, ...data: any[]) => {
  const randomColor = getRandomColor();

  window.console.log(
    `\n\n%c =======  ${title} ======= %c${moment().format("HH:mm:ss SSS")} \n`,
    `color:${randomColor};font-size:15`,
    `color:${getRandomColor()};font-size:15`
  );
  window.console.log(``);
  window.console.log(`    `, data);
  window.console.log(`\n =========================================`);
};

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const updateObject = (oldObject: any, updatedProperties: any) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

const getStateSlice = (state: any) => state.toJS().locale;

const getNameInitials = (name: any = "") => {
  let abbr = "";
  let str = name || "";
  str = str.split(" ");
  for (let i = 0; i < str.length; i++) {
    abbr += str[i].substr(0, 1);
  }

  if (abbr[1]) {
    return abbr[0].toUpperCase() + abbr[1].toUpperCase();
  }
  return abbr[0].toUpperCase();
};

const getColorInitial = (name: any = "") => {
  let abbr = "";
  let str = name || "";
  str = str.split(" ");
  for (let i = 0; i < str.length; i++) {
    abbr += str[i].substr(0, 1);
  }

  return abbr[0].toUpperCase();
};

const convertGroupLink = (link: any = "") => {
  let groupLink = link.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "") || "";
  groupLink = groupLink.split("/")[0];

  return groupLink;
};

const getGroupSlugChannel = (path: string) => {
  const groupSlug = path.includes("/group/")
    ? (path = path.replace("/group/", ""))
    : path.replace("/channel/", "");
  const slug = groupSlug.split("/")[0];
  let channel: any = groupSlug.split("/")[1];

  if (channel) {
    channel = +channel;
  }
  return { slug, channel };
};

const convertWindowsLink = (link: any = "") => {
  let historyLink = link.replace(/^(?:\/group\/?:\/\/)?/i, "") || "";
  historyLink = historyLink.replace("/group/", "");
  return historyLink;
};

const getUrlFromString = (content: any = "") => {
  const urlRegex = /(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/;
  const matchedUrl = content.match(urlRegex);
  return matchedUrl;
};

const getDomainFromUrl = (url: string) => {
  const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  return matches && matches[1];
};

export default {
  logger,
  getRandomColor,
  updateObject,
  getStateSlice,
  getNameInitials,
  getColorInitial,
  convertGroupLink,
  convertWindowsLink,
  getUrlFromString,
  getGroupSlugChannel,
  getDomainFromUrl
};
