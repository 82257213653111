import { IUserService } from "../../../core/services/users";
import { injectable } from "inversify";
import databaseInstance from "../../index";
import { globalActions } from "../../../store/actions";
import store from "../../../store/configureStore";

// import {Profile} from "../../../core/models/users";

@injectable()
export class UserService implements IUserService {
  private dbInstance: any = databaseInstance;

  private static instance: UserService;

  constructor() {
    UserService.instance = this;
  }

  public static getInstance() {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public updateUserProfile: (userId: number, payload: any) => Promise<void> = (
    userId,
    payload
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.put(`/users/${userId}`, payload).then((response: any) => {
        resolve(response);
      });
    });
  };

  public getUserProfile: (userId: number) => Promise<void> = userId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/users/${userId}`).then((response: any) => {
        resolve(response);
      });
    });
  };

  public deleteUser: (userId: number) => Promise<void> = userId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`/users/${userId}`).then((response: any) => {
        resolve(response);
      });
    });
  };

  public resetPassword: (email: string) => Promise<void> = email => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.post("/password/reset").then((response: any) => {
        resolve(response);
      });
    });
  };

  public updateUserProfileImage: (
    userId: number,
    profileImage: any
  ) => Promise<void> = (userId, profileImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/users/${userId}/profile-image`, profileImage)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updateUserPassword: (userId: number, payload: any) => Promise<void> = (
    userId,
    payload
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.put(`/users/${userId}/password`, payload).then(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          store.store.dispatch(globalActions.showMessage(error.data));
        }
      );
    });
  };
}
