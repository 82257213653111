import React, { useEffect, useState } from "react";
import { ISidebarForChannelProps } from "./ISidebarForChannelProps";

import "./SidebarForChannelComponent.scss";
import miscUtils from "../../../utils/miscUtils";
import { push } from "connected-react-router";
import { globalActions } from "../../../store/actions";
import { Map } from "immutable";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { GroupService } from "../../../api/services/groups";
import { ChannelService } from "../../../api/services/channels";
import { SearchIcon } from "../../../constants/icons";
const SidebarForChannelComponent: React.FC<ISidebarForChannelProps> = (
  props: any
) => {
  const {
    global: { currentView },
    subdomain
  } = props;

  const [groupChannels, setGroupChannels] = useState<any>([]);
  const [selectedChannel, selectChannel] = useState<number>(-1);
  const [selectedGroup, selectGroup] = useState<any>(-1);
  const [postsSearchQuery, setPostsSearchQuery] = useState<string>("");

  useEffect(() => {
    if (props.location.pathname) {
      const groupData: any = miscUtils.getGroupSlugChannel(
        props.location.pathname
      );

      switchToGroup(subdomain || groupData.slug, groupData.channel);
    }
  }, [props.location.pathname]);

  function viewChannel(channelId: number) {
    selectChannel(channelId);
    props.goToChannel(selectedGroup, channelId);
  }

  function switchToGroup(groupSlug: string, channelId?: number) {
    GroupService.getInstance()
      .getGroup(subdomain || groupSlug)
      .then((groupData: any) => {
        if (groupData && groupData.id) {
          ChannelService.getInstance()
            .getGroupChannels(groupData.id, "")
            .then((channels: any) => {
              setGroupChannels(channels.data);
              selectGroup(subdomain || groupData.slug);
              selectChannel(
                channelId ? channelId : groupData.default_channel.id
              );
            });
        }
      });
  }

  return (
    <div className="sidebar-channel-only__wrap">
      <div className="sidebar__content-channels-list">
        <div className="group-name">{currentView}</div>

        <div className="sidebar-channel__search-wrap">
          <input
            placeholder={"Search group"}
            value={postsSearchQuery}
            onChange={e => setPostsSearchQuery(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                props.setPostsQuery(postsSearchQuery);
              }
            }}
            className="channel-search"
          />
          <span>
            <SearchIcon />
          </span>
        </div>
        <div className="joined-channels">
          <p className="title">Channels</p>
          <ul>
            {groupChannels &&
              groupChannels.map((channel: any, index: number) => {
                if (channel.status === "open") {
                  return (
                    <li
                      key={index}
                      onClick={() => viewChannel(channel.id)}
                      className={selectedChannel === channel.id ? "active" : ""}
                    >
                      #{channel.name}
                    </li>
                  );
                }
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: ISidebarForChannelProps) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const groups = Map(state.get("group", {})).toJS() as any;
  const uid: string = state.getIn(["authorize", "uid"]);
  return {
    uid,
    global,
    groups
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ISidebarForChannelProps
) => {
  return {
    goToChannel: (groupSlug: string, channelId: number) => {
      dispatch(push(`/channel/${groupSlug}/${channelId}`));
    },
    setPostsQuery: (query: string) =>
      dispatch(globalActions.setPostsQuery(query))
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarForChannelComponent as any) as any) as any;
