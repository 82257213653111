// import { push } from "connected-react-router";
import { SocialError } from "../../core/models/common";
import { globalActions } from "./index";
import { authorizeService, login } from "./authorizeActions";
import { provider } from "../../groupApp";
import { SocialProviderTypes } from "../../core/socialProviderTypes";
import { IUserService } from "../../core/services/users";

export const dbLogin = (email: string, password: string) => {
  return (dispatch: any, getState: any) => {
    return authorizeService.login(email, password).then(
      (result: any) => {
        dispatch(login(result));
        // dispatch(push("/home"));
      },
      (error: SocialError) => {
        dispatch(globalActions.showMessage(error.code));
      }
    );
  };
};

export const userService: IUserService = provider.get<IUserService>(
  SocialProviderTypes.UserService
);

export const updatePassword = (userId: number, payload: any) => {
  return (dispatch: any) => {
    return userService
      .updateUserPassword(userId, payload)
      .then((result: any) => {
        dispatch(globalActions.showMessage(result.message));
      })
      .catch((err: any) => {
        dispatch(globalActions.showMessage(err.data));
      });
  };
};
