import { Map } from "immutable";
import { GroupState } from "./IGroupState";
import { IGroupAction } from "./IGroupAction";
import { GroupActionType } from "../../../constants/groupActionType";

export let groupReducer = (
  // @ts-ignore
  state = Map(new GroupState()),
  action: IGroupAction
) => {
  const { payload } = action;
  switch (action.type) {
    case GroupActionType.GET:
      return state.set("userGroups", payload.groups);
    case GroupActionType.LOAD:
      return state.set("loadSidebarGroup", !state.get("loadSidebarGroup"));
    case GroupActionType.POST:
      return state.set("postData", payload);
    default:
      return state;
  }
};
