import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, withRouter, Route } from "react-router-dom";
import Loadable from "react-loadable";

import MainLoadingComponent from "components/mainLoading";
import { IRouterProps } from "routes/IRouterProps";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

const AsyncGroupView: any = Loadable({
  loader: () => import("containers/groupView"),
  loading: MainLoadingComponent
});

const AsyncGroupMembersPublic: any = Loadable({
  loader: () => import("containers/groupMembersPublic"),
  loading: MainLoadingComponent
});

const AsyncGroupSettings: any = Loadable({
  loader: () => import("containers/groupSettings"),
  loading: MainLoadingComponent
});

const AsyncSinglePost: any = Loadable({
  loader: () => import("containers/singlePost"),
  loading: MainLoadingComponent
});

const AsyncNotifications: any = Loadable({
  loader: () => import("containers/notifications"),
  loading: MainLoadingComponent
});

const AsyncRouterSwitch: any = Loadable({
  loader: () => import("containers/routerSwitch"),
  loading: MainLoadingComponent
});

const AsyncSearch: any = Loadable({
  loader: () => import("containers/search"),
  loading: MainLoadingComponent
});

export class SubdomainRouter extends Component<IRouterProps, any> {
  public render() {
    const { subdomain, isAuthed } = this.props;

    return subdomain ? (
      <Switch>
        <PublicRoute
          authed={isAuthed}
          exact={true}
          path="/"
          disableRedirect={true}
          component={<AsyncGroupView subdomain={subdomain} />}
        />
        <PublicRoute
          exact={true}
          path="/posts/:id"
          component={<AsyncSinglePost />}
        />
        <PublicRoute
          exact={true}
          path="/channel/:channelId"
          disableRedirect={true}
          component={<AsyncGroupView subdomain={subdomain} />}
        />
        <PrivateRoute
          authed={isAuthed}
          exact={true}
          path={"/notifications"}
          component={<AsyncNotifications />}
        />
        <Route
          authed={isAuthed}
          group={true}
          exact={true}
          path={"/members"}
          component={AsyncGroupMembersPublic}
        />
        <PrivateRoute
          authed={isAuthed}
          exact={true}
          path={"/search"}
          component={<AsyncSearch />}
        />
        <PrivateRoute
          authed={isAuthed}
          exact={false}
          path="/settings"
          component={
            <AsyncGroupSettings isAuthed={isAuthed} subdomain={subdomain} />
          }
        />
        <Route
          render={() => {
            return (
              <AsyncRouterSwitch switchToMain={true} subdomain={subdomain} />
            );
          }}
        />
      </Switch>
    ) : (
      ""
    );
  }
}

// @ts-ignore
export default withRouter<any>(
  connect(
    null,
    null
  )(SubdomainRouter as any)
) as typeof SubdomainRouter;
