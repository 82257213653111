import { GlobalActionType } from "../../../constants/globalActionType";

import { GlobalState } from "./GlobalState";
import { IGlobalAction } from "./IGlobalAction";
import { Map } from "immutable";

export const globalReducer = (
  state = Map(new GlobalState()),
  action: IGlobalAction
) => {
  // const { payload } = action;
  switch (action.type) {
    case GlobalActionType.SHOW_MESSAGE_GLOBAL:
      return state.set("message", action.payload);

    case GlobalActionType.HIDE_TOP_LOADING:
      return state.set("showTopLoading", false);

    case GlobalActionType.SHOW_TOP_LOADING:
      return state.set("showTopLoading", true);

    case GlobalActionType.SET_CHANNEL_SIDEBAR_STATUS:
      return state.set("showChannelSidebar", action.payload);

    case GlobalActionType.SET_SIDEBAR_FOR_CHANNEL:
      return state.set("showSidebarForChannel", action.payload);

    case GlobalActionType.TOGGLE_HAMBURGER_MENU:
      return state.set("toggleSidebar", !state.get("toggleSidebar"));

    case GlobalActionType.SET_CURRENT_VIEW:
      return state.set("currentView", action.searchQuery);

    case GlobalActionType.SET_POSTS_QUERY:
      return state.set("postsQuery", action.searchQuery);

    case GlobalActionType.REFRESH_SIDEBAR:
      return state.set("refreshSidebar", action.payload);

    case GlobalActionType.SHOW_PUBLIC_GROUP_LOGIN:
      return state.set(
        "showPublicLoginPopup",
        !state.get("showPublicLoginPopup")
      );

    case GlobalActionType.SHOW_PUBLIC_GROUP_SIGNUP:
      return state.set(
        "showPublicSignupPopup",
        !state.get("showPublicSignupPopup")
      );

    default:
      return state;
  }
};
