import miscUtils from "../../../utils/miscUtils";

export class Post {
  // Index Signature
  [key: string]: any;

  public id: number = -1;
  public channel_id: number = -1;
  public comments_count: number = -1;
  public created_at: string = "";
  public content: string = "";
  public group_id: number = -1;
  public liked: boolean = false;
  public reaction_like_count: number = -1;
  public reaction_like_weight: string = "";
  public time_difference: string = "";
  public updated_at: string = "";

  public meta_data: any;
  public group: any = null;
  public author: any = null; // TODO replace with Author or User

  constructor(instanceData?: Post) {
    if (instanceData) {
      this.deserialize(instanceData);
    }
  }

  private deserialize(instanceData: Post) {
    const keys: string[] = Object.keys(this);
    for (const key of keys) {
      if (instanceData.hasOwnProperty(key)) {
        this[key] = instanceData[key];
      }
    }
  }

  public get $updated_at(): Date {
    return new Date(this.updated_at);
  }

  public get $created_at(): Date {
    return new Date(this.created_at);
  }

  public get $content(): string {
    return this.content;
  }

  public get url(): string {
    let postUrl = miscUtils.getUrlFromString(this.content);
    if (postUrl && postUrl[0]) {
      postUrl = postUrl[0];
    }
    return postUrl;
  }
}
