import React from "react";
import { ILoginComponentProps } from "./ILoginComponentProps";

import { withRouter } from "react-router-dom";

import "./LoginComponent.scss";
import Login from "../../components/loginComponent/";
import Cover from "../../assets/img/pexels-photo.jpg";

const LoginComponent: React.FC<ILoginComponentProps> = () => {
  return (
    <div className="modal home-acc__login flex flex-align-center">
      <div className="home--acc__login-media">
        <img src={Cover} alt="" />
      </div>
      <div className="home--acc__login-component flex flex-align-center flex-justify-center">
        <Login subtitle="Login to your account." extClass="" noAccount={true} />
      </div>
    </div>
  );
};

export default withRouter((LoginComponent as any) as any) as any;
