import { INotificationService } from "../../../core/services/notification";
import { injectable } from "inversify";
import databaseInstance from "../../index";

@injectable()
export class NotificationService implements INotificationService {
  private dbInstance: any = databaseInstance;

  private static instance: NotificationService;

  constructor() {
    NotificationService.instance = this;
  }

  public static getInstance() {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  public getUnreadNotifications: (
    userId: number,
    currentPage: any
  ) => Promise<void> = (userId, currentPage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/users/${userId}/unread-notifications?limit=30&page=${currentPage}`
        )
        .then((response: any) => {
          resolve(response);
        });
    });
  };
}
