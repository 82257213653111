import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCog } from "@fortawesome/free-solid-svg-icons";
import useComponentVisible from "../../../utils/visible/visible";

import { ITopBarMenuComponentProps } from "./ITopBarMenuComponentsProps";
import { IMainComponentProps } from "../../../containers/main/IMainComponentProps";
import { authorizeActions } from "../../../store/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const TopBarMenuComponent: React.FC<ITopBarMenuComponentProps> = props => {
  const { logout } = props;

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  return (
    <div className="header-settings dropdown-menu" ref={ref}>
      <button onClick={() => setIsComponentVisible(true)}>
        <FontAwesomeIcon icon={faCog} />
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      {isComponentVisible && (
        <ul className="group-dropdown dropdown flex flex-direction-column">
          <li>
            <Link
              onClick={() => {
                setIsComponentVisible(false);
              }}
              className="btn btn-top-bar"
              to={{ pathname: "/about", search: "?section=update-password" }}
            >
              Update Password
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setIsComponentVisible(false)}
              className="btn btn-top-bar"
              to={{ pathname: "/about", search: "?section=profile-details" }}
            >
              Profile Details
            </Link>
          </li>
          <li>
            <button
              onClick={() => {
                setIsComponentVisible(false);
              }}
              className="btn btn-top-bar"
            >
              Privacy Policy
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setIsComponentVisible(false);
              }}
              className="btn btn-top-bar"
            >
              Terms of Service
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                setIsComponentVisible(false);
              }}
              className="btn btn-top-bar"
            >
              Help
            </button>
          </li>
          <li>
            <button onClick={() => logout()} className="btn btn-link">
              Logout
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any, ownProps: IMainComponentProps) => {
  return {
    logout: () => {
      dispatch(authorizeActions.dbLogout());
    }
  };
};

export default withRouter(connect(
  null,
  mapDispatchToProps
)(TopBarMenuComponent as any) as any);
