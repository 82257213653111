import React from "react";

export const PlusWithCircle = () => (
  <svg
    width="23px"
    height="23px"
    viewBox="0 0 23 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Desktop-view"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop-Welcome-page"
        transform="translate(-23.000000, -121.000000)"
        fill="#365DE6"
        fillRule="nonzero"
      >
        <path d="M34.5,121 C28.1549168,121 23,126.154912 23,132.5 C23,138.845073 28.1549168,144 34.5,144 C40.8450832,144 46,138.845073 46,132.5 C46,126.154912 40.8450832,121 34.5,121 Z M34.5,122 C40.3052085,122 45,126.694786 45,132.5 C45,138.305214 40.3052085,143 34.5,143 C28.6947915,143 24,138.305214 24,132.5 C24,126.694786 28.6947915,122 34.5,122 Z M34.4500749,126.003255 C34.1717343,126.033876 33.9628821,126.272304 33.9689044,126.552564 L33.9689044,131.961143 L28.5662884,131.961143 C28.5494145,131.960351 28.5325127,131.960351 28.5156388,131.961143 C28.3225944,131.970164 28.1490256,132.081614 28.0603294,132.253502 C27.9716332,132.425389 27.9812884,132.631594 28.0856571,132.794422 C28.1900258,132.95725 28.3732476,133.051957 28.5662884,133.042859 L33.9689044,133.042859 L33.9689044,138.451439 C33.9661489,138.646492 34.0685153,138.82792 34.2368055,138.926251 C34.4050957,139.024583 34.6132364,139.024583 34.7815266,138.926251 C34.9498168,138.82792 35.0521831,138.646492 35.0494276,138.451439 L35.0494276,133.042859 L40.4520437,133.042859 C40.6468815,133.045618 40.8281098,132.943139 40.9263328,132.774663 C41.0245557,132.606187 41.0245557,132.397816 40.9263328,132.22934 C40.8281098,132.060864 40.6468815,131.958385 40.4520437,131.961143 L35.0494276,131.961143 L35.0494276,126.552564 C35.0527793,126.396799 34.9888776,126.247151 34.8740877,126.141946 C34.7592977,126.036741 34.6047685,125.986196 34.4500749,126.003255 Z" />
      </g>
    </g>
  </svg>
);

export const CommentIcon = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Desktop-view"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop-Home-screen-channels"
        transform="translate(-646.000000, -734.000000)"
        fill="#000000"
        fillRule="nonzero"
        stroke="#33353B"
      >
        <g id="Group-4" transform="translate(578.000000, 732.000000)">
          <g
            id="noun_comment_1026792"
            transform="translate(69.000000, 3.000000)"
          >
            <path d="M17.980628,8.45147213 C17.7200621,3.92776685 14.0958277,0.304065754 9.57145657,0.0198539041 C7.01317342,-0.145936342 4.59701712,0.73038353 2.74936818,2.50670759 C0.972782665,4.23566302 -0.0457930309,6.67514807 0.00158258283,9.18568608 C0.0963338104,13.9225502 4.02850975,17.8541475 8.74238333,17.9962534 C10.376842,18.0436221 11.9876129,17.6409886 13.4088813,16.8357217 L17.3884328,17.9725691 C17.4358085,17.9962534 17.4831841,17.9962534 17.5305597,17.9962534 C17.6489987,17.9962534 17.7911256,17.9488848 17.862189,17.8541475 C17.980628,17.7357259 18.0280036,17.5462513 17.980628,17.3804611 L16.7488621,13.5672854 C17.6726865,12.0278046 18.099067,10.2514805 17.980628,8.45147213 Z M15.8542383,13.1146654 C15.7363616,13.3028544 15.7127862,13.514567 15.7835122,13.7262796 L16.7736767,16.7608269 L13.6381559,15.8669292 C13.4024025,15.7963584 13.166649,15.819882 12.9544709,15.9610237 C11.6814024,16.6667324 10.2433064,17.0431104 8.78163504,16.9960631 C4.58522379,16.878445 1.07249751,13.3734253 1.00177148,9.16269691 C0.954620792,6.9279528 1.85048387,4.76377956 3.47718261,3.21122049 C4.96242929,1.77627953 6.91918285,1 8.99381314,1 C9.15884055,1 9.3474433,1 9.51247071,1.02352362 C13.5438546,1.28228347 16.7501014,4.50501971 16.9858548,8.52755911 C17.0801562,10.150689 16.7029507,11.7267717 15.8542383,13.1146654 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export const HeartIcon = () => (
  <svg
    width="22px"
    height="19px"
    viewBox="0 0 22 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Desktop-view"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Desktop-Home-screen-channels"
        transform="translate(-579.000000, -1222.000000)"
        fill="#33353B"
        fillRule="nonzero"
      >
        <g id="Group-2" transform="translate(544.000000, 834.000000)">
          <g id="Group-4" transform="translate(35.000000, 385.000000)">
            <g
              id="noun_Heart_2349199"
              transform="translate(0.000000, 3.000000)"
            >
              <path
                d="M11,19 C10.8307692,19 10.6897436,18.9446684 10.5487179,18.8616709 C5.44358974,15.7354343 0,12.0005498 0,7.10370129 C0,2.64950573 2.70769231,0.546904097 5.04871795,0.104251122 C7.05128205,-0.283070232 9.47692308,0.380909231 11,2.5665083 C12.5230769,0.380909231 14.9769231,-0.283070232 16.9512821,0.104251122 C19.2923077,0.546904097 22,2.64950573 22,7.10370129 C22,12.0005498 16.5564103,15.7354343 11.4512821,18.8616709 C11.3102564,18.9446684 11.1692308,19 11,19 Z M6.16666667,1.82685947 C5.91666667,1.82685947 5.66666667,1.8542456 5.44444444,1.88163172 C2.77777778,2.40196812 1.83333333,5.05842235 1.83333333,7.19454018 C1.83333333,11.3572313 7.55555556,14.5477201 11,16.6838379 C14.4444444,14.5477201 20.1666667,11.3572313 20.1666667,7.19454018 C20.1666667,5.05842235 19.2222222,2.40196812 16.5555556,1.88163172 C14.8888889,1.55299821 12.75,2.31980974 11.7777778,4.75717496 C11.6388889,5.08580847 11.3333333,5.27751136 11,5.27751136 C10.6666667,5.27751136 10.3611111,5.08580847 10.2222222,4.75717496 C9.38888889,2.67582938 7.69444444,1.82685947 6.16666667,1.82685947 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    =
  </svg>
);

export const CameraIcon = () => (
  <svg
    width="27px"
    height="22px"
    viewBox="0 0 27 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Mobile-version-Design"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Edit-Group-Details"
        transform="translate(-67.000000, -183.000000)"
        fill="#33353B"
        fillRule="nonzero"
      >
        <path d="M70.375,205 C68.5110887,205 67,203.507567 67,201.666668 L67,189.666665 C67,187.825765 68.5110887,186.333332 70.375,186.333332 L74.1929687,186.333332 L75.4585937,183.218733 C75.5072356,183.087543 75.6335438,183.000225 75.775,183 L85.225,183 C85.3664562,183.000225 85.4927644,183.087543 85.5414063,183.218733 L86.8070312,186.333332 L90.625,186.333332 C92.4889113,186.333332 94,187.825765 94,189.666665 L94,201.666668 C94,203.507567 92.4889113,205 90.625,205 L70.375,205 L70.375,205 Z M70.7368421,204 L90.2631579,204 C91.7852868,204 93,202.797344 93,201.290323 L93,190.096772 C93,188.589752 91.7852868,187.387096 90.2631579,187.387096 L86.1578947,187.387096 C86.0171903,187.388542 85.8899393,187.30455 85.8371711,187.175402 L84.5542763,184 L76.4457237,184 L75.1628289,187.175402 C75.1100607,187.30455 74.9828097,187.388542 74.8421053,187.387096 L70.7368421,187.387096 C69.2147132,187.387096 68,188.589752 68,190.096772 L68,201.290323 C68,202.797344 69.2147132,204 70.7368421,204 Z M81,201 C77.6905059,201 75,198.309531 75,195 C75,191.690469 77.6905059,189 81,189 C84.3095294,189 87,191.690469 87,195 C87,198.309531 84.3095294,201 81,201 Z M81,200 C83.7654,200 86,197.765368 86,195 C86,192.234632 83.7654,190 81,190 C78.2347,190 76,192.234632 76,195 C76,197.765368 78.2347,200 81,200 Z" />
      </g>
    </g>
  </svg>
);

export const SearchIcon = () => (
  <svg
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Mobile-version-Design"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Request-to-join-group"
        transform="translate(-17.000000, -36.000000)"
        fill="#767A8A"
        fillRule="nonzero"
      >
        <g id="noun_Search_2358273" transform="translate(17.000000, 36.000000)">
          <path d="M20.8193515,19.9438235 L14.6495111,13.7735294 C15.9094184,12.3097059 16.6814205,10.4135294 16.6814205,8.33823529 C16.6752445,3.74294118 12.9325785,0 8.33762223,0 C3.74266598,0 0,3.74294118 0,8.33823529 C0,12.9335294 3.74266598,16.6764706 8.33762223,16.6764706 C10.4127638,16.6764706 12.3088008,15.9105882 13.7725167,14.6444118 L19.9423572,20.8147059 C20.0658775,20.9382353 20.2202779,21 20.3808543,21 C20.5414308,21 20.6958312,20.9382353 20.8193515,20.8208824 C21.0602162,20.58 21.0602162,20.1847059 20.8193515,19.9438235 Z M8.33762223,15.4411765 C4.42202779,15.4411765 1.23520329,12.2541176 1.23520329,8.33823529 C1.23520329,4.42235294 4.42202779,1.23529412 8.33762223,1.23529412 C12.2532167,1.23529412 15.4400412,4.42235294 15.4400412,8.33823529 C15.4400412,12.2541176 12.2532167,15.4411765 8.33762223,15.4411765 Z" />
        </g>
      </g>
    </g>
  </svg>
);
export const CloseIcon = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="times"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 352 512"
  >
    <path
      fill="currentColor"
      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
      className=""
    />
  </svg>
);
