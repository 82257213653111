import { push } from "connected-react-router";
import { SocialError } from "../../core/models/common/socialError";
import { SocialProviderTypes } from "../../core/socialProviderTypes";
import { IAuthorizeService } from "../../core/services/authorize";
import { User } from "../../core/models/users";
import { globalActions } from "./index";
import { provider } from "../../groupApp";
import { AuthorizeActionType } from "constants/authorizeActionType";
import { GlobalActionType } from "../../constants/globalActionType";
import { removeCookieValue } from "../../utils/cookieStorage";
import { CommonConstants } from "../../constants/common";

export const login = (user: any) => {
  return {
    type: AuthorizeActionType.LOGIN,
    payload: { authed: true, uid: user.id, user }
  };
};

export const logout = () => {
  return { type: AuthorizeActionType.LOGOUT };
};

export const signup = (user: User) => {
  console.log(user);
  return {
    type: AuthorizeActionType.SIGNUP,
    payload: { ...user, user }
  };
};

export const updatePassword = () => {
  return { type: AuthorizeActionType.UPDATE_PASSWORD };
};

export const authorizeService: IAuthorizeService = provider.get<IAuthorizeService>(SocialProviderTypes.AuthorizeService);

export const dbLogin = (email: string, password: string) => {
  return (dispatch: any, getState: any) => {
    return authorizeService.login(email, password).then(
      (result: any) => {
        dispatch(globalActions.clearMessage());
        if (result && result.uid) {
          dispatch(login(result));
          dispatch(push("/"));
        }
      },
      (error: SocialError) => {
        dispatch(globalActions.showMessage(error.data));
      }
    );
  };
};

export const dbForgotPassword = (email: string) => {
  return (dispatch: any, getState: any) => {
    return authorizeService.forgotPassword(email).then(
      (result: any) => {
        dispatch(push("/reset-password", { email }));
      },
      (error: SocialError) => {
        dispatch(globalActions.hideTopLoading());
        dispatch(globalActions.showMessage(error.code));
      }
    );
  };
};

export const dbLogout = () => {
  return (dispatch: any, getState: any) => {
    removeCookieValue(CommonConstants.USER_KEY);
    dispatch(logout());
    dispatch({ type: GlobalActionType.HANDLE_CLEAR_STORE });
    dispatch(push("/login"));
  };
};

export const dbSignup = (user: User) => {
  return (dispatch: any, getState: any) => {
    const newUser = new User();
    newUser.email = user.email;
    newUser.password = user.password;
    newUser.full_name = user.full_name;

    return authorizeService
      .registerUser(newUser)
      .then((result: any) => {
        dispatch(
          signup({
            ...user,
            id: result._uid
          })
        );
        dispatch(push("/home"));
      })
      .catch((error: SocialError) => {
        dispatch(globalActions.hideTopLoading());
        dispatch(globalActions.showMessage(error.message));
      });
  };
};
