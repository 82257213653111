import React, { useState, useEffect } from "react";
import validator from "./validatorSchema";

const useForm = (callback: any, state: any) => {
  const [value, setValue] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, isSubmitting]);

  useEffect(() => {
    const key = Object.keys(value).filter(k => value[k] !== state[k]);

    if (key.length > 0) {
      if (isSubmitting) {
        setIsSubmitting(false);
      }

      const property: string = key.toString();
      validator[property].errors = [];
      validator[property].invalid = false;
      delete errors[property];
      // @ts-ignore
      setErrors(errors => ({ ...errors }));
      // @ts-ignore
      setValue(value => ({ ...value, [property]: state[property] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, state]);

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    Object.keys(state).map(key => updateValidators(event, key, state[key]));
    setValue(state);
    setIsSubmitting(true);
  };

  const updateValidators = (event: any, fieldName: any, value: any) => {
    event.persist();
    if (validator[fieldName]) {
      validator[fieldName].errors = [];
      validator[fieldName].state = value;
      validator[fieldName].valid = true;
      validator[fieldName].rules.forEach((rule: any) => {
        if (rule.test instanceof RegExp) {
          const testFailed = rule.invertTest
            ? !rule.test.test(value)
            : rule.test.test(value);
          if (testFailed) {
            validator[fieldName].errors = [rule.message];
            validator[fieldName].valid = false;
          }
        } else if (typeof rule.test === "function") {
          if (!rule.test(value)) {
            validator[fieldName].errors = [rule.message];
            validator[fieldName].valid = false;
          }
        } else if (typeof rule.matchPassword === "function") {
          if (!rule.matchPassword(value, validator.password.state)) {
            validator[fieldName].errors = [rule.message];
            validator[fieldName].valid = false;
          }
        }
      });
      handleMessage(fieldName);
    }
  };

  const handleMessage = (fieldName: any) => {
    const validate = validator[fieldName];

    if (validate && !validate.valid) {
      const errorsMessage = validate.errors.map((item: any, index: any) => {
        return (
          <span className="error" key={index}>
            * {item}
          </span>
        );
      });

      const errorWrap = <div className="col s12 row">{errorsMessage}</div>;

      // @ts-ignore
      setErrors(errors => ({
        ...errors,
        [fieldName]: { errorComponent: errorWrap, invalid: !validate.invalid }
      }));
    }
  };

  return {
    handleSubmit,
    errors
  };
};

export default useForm;
