import Cookie from "js-cookie";
import appConfig from "../appConfig";

export function getCookieValue(key: string) {
  const value = Cookie.get(key);
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
}

export function setCookieValue(key: string, value: any) {
  Cookie.set(key, value, { path: "/", domain: appConfig.domain.baseDomain });
}

export function removeCookieValue(key: string) {
  Cookie.remove(key, { path: "/", domain: appConfig.domain.baseDomain });
}
