import React, { useEffect, useState } from "react";

import { ISidebarComponentProps } from "./ITopBarComponentsProps";
import { Map } from "immutable";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

import "./SidebarComponent.scss";
import { PlusWithCircle, SearchIcon } from "../../constants/icons";
import { push } from "connected-react-router";
import miscUtils from "../../utils/miscUtils";
import { Link } from "react-router-dom";
import { globalActions } from "../../store/actions";
import { ChannelService } from "../../api/services/channels";
import { getCookieValue } from "../../utils/cookieStorage";
import { GroupService } from "../../api/services/groups";

const SidebarComponent: React.FC<ISidebarComponentProps> = (props: any) => {
  const {
    uid,
    subdomain,
    groups: { userGroups, loadSidebarGroup, postData },
    global: { currentView, refreshSidebar },
    setChannelSidebarStatus
  } = props;

  const [isSideBar, setIsSideBar] = useState<any>("");

  useEffect(() => {
    setIsSideBar(getCookieValue("sidebar"));
  }, []);

  const [groupChannels, setGroupChannels] = useState<{ data: [] }>({
    data: []
  });
  const [selectedChannel, selectChannel] = useState<number>(-1);
  const [selectedGroup, selectGroup] = useState<any>(-1);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [postsSearchQuery, setPostsSearchQuery] = useState<string>("");
  const [isGroup, setIsGroup] = useState<boolean>(false);

  useEffect(() => {
    if (props.location.pathname) {
      const groupData: any = miscUtils.getGroupSlugChannel(
        props.location.pathname
      );

      setIsGroup(subdomain || groupData.slug.length > 0);
      switchToGroup(subdomain || groupData.slug, groupData.channel);
    }
  }, [props.location.pathname, subdomain, refreshSidebar]);

  useEffect(() => {
    checkSelectedGroupPermission();
  }, [userGroups, selectedGroup, selectedChannel]);

  useEffect(() => {
    checkPostPage(
      postData && postData.channel_id,
      postData && postData.group.slug
    );
  }, [postData]);

  function switchToGroup(groupSlug: string, channelId?: number) {
    GroupService.getInstance()
      .getGroup(groupSlug)
      .then((groupData: any) => {
        if (groupData && groupData.id) {
          ChannelService.getInstance()
            .getGroupAndUserChannels(groupData.id, uid)
            .then((channels: any) => {
              setGroupChannels(channels);
              selectGroup(groupData.slug);
              selectChannel(
                channelId ? channelId : groupData.default_channel.id
              );
            });
        }
      });
  }

  function viewChannel(channelId: number) {
    selectChannel(channelId);
    props.goToChannel(selectedGroup, channelId, subdomain);
  }

  function checkPostPage(channelId: number, group: string) {
    if (group) {
      selectChannel(channelId);
      setIsGroup(group.length > 0);
      switchToGroup(group, channelId);
      setTimeout(() => setChannelSidebarStatus(true), 2000);
    }
  }

  function checkSelectedGroupPermission() {
    if (userGroups && selectedGroup) {
      let isAdminOfSelectedGroup = false;
      userGroups.forEach((group: any) => {
        if (group.group.slug == selectedGroup && (group.admin || group.owner)) {
          isAdminOfSelectedGroup = true;
        }
      });
      setIsAdmin(isAdminOfSelectedGroup);
    }
  }

  const MyLoader = () => {
    return (
      <ContentLoader
        height={162}
        width={280}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="0" y="0" rx="5" ry="5" width="280" height="42" />
        <rect x="0" y="60" rx="4" ry="4" width="280" height="42" />
        <rect x="0" y="120" rx="5" ry="5" width="280" height="42" />
      </ContentLoader>
    );
  };

  function isGroupSelected(item: any) {
    let isSelected = false;
    if (subdomain) {
      isSelected = window.location.hostname.includes(item.group.subdomain);
    } else {
      isSelected = miscUtils
        .convertWindowsLink(window.location.pathname)
        .includes(item.group.slug);
    }

    if (isSelected) {
      return "sidebar__group-item--picture-box sidebar__group-item--picture-active flex flex-justify-center flex-align-center";
    }
    return "sidebar__group-item--picture-box flex flex-justify-center flex-align-center";
  }

  return (
    <div
      className={
        userGroups && userGroups.length > 0 ? "sidebar" : "sidebar new-user"
      }
    >
      <div className="sidebar__wrap">
        <div className="sidebar__content">
          {loadSidebarGroup ? (
            <div className="sidebar-content-loader">
              <MyLoader />
            </div>
          ) : userGroups && userGroups.length > 0 ? (
            <div className="sidebar__content-group-list">
              <div className="sidebar__content-group-list--container">
                <div
                  className={
                    isSideBar
                      ? "sidebar__header sidebar-is-open flex flex-align-center flex-justify-space-between"
                      : "sidebar__header flex flex-align-center flex-justify-space-between"
                  }
                >
                  <span className="sidebar__header-title">Groups</span>
                  <button
                    onClick={() => {
                      props.createGroup();
                    }}
                  >
                    Create a Group
                  </button>
                </div>
                <div className="sidebar__group-item--container">
                  <div className="sidebar__scrollarea">
                    <div className="sidebar__group-item--wrap">
                      {userGroups.length &&
                        userGroups.map((item: any, index: any) => {
                          if (!item.blocked) {
                            return (
                              <div className="sidebar__group-item" key={index}>
                                <Link
                                  className="sidebar__group-item-link"
                                  to={`/group/${item.group && item.group.slug}`}
                                >
                                  <div className="flex">
                                    <div className="sidebar__group-item--picture">
                                      {/*<span className="group__new-posts">1</span>*/}
                                      <div
                                        className={isGroupSelected(item)}
                                        data-color={miscUtils.getColorInitial(
                                          `${item.group.name}`
                                        )}
                                      >
                                        {item.group.profile_image ? (
                                          <img
                                            src={`${item.group.profile_image}`}
                                            alt={`${item.group.name}`}
                                          />
                                        ) : (
                                          <span>
                                            {miscUtils.getNameInitials(
                                              `${item.group.name}`
                                            )}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="sidebar__group-item--info flex flex-direction-column flex-justify-center">
                                      <h5>{item.group.name}</h5>
                                      <span
                                        className="group-link"
                                        title={miscUtils.convertGroupLink(
                                          `${item.group.subdomain}.group.app`
                                        )}
                                      >
                                        {miscUtils.convertGroupLink(
                                          `${item.group.subdomain}.group.app`
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {isGroup && (
                <div className="sidebar__content-channels-list">
                  <div className="group-name">{currentView}</div>
                  {isAdmin && (
                    <Link
                      to={
                        subdomain
                          ? `/settings/members`
                          : `/group/${selectedGroup}/settings/members`
                      }
                      className={"admin-link"}
                    >
                      Admin Panel
                    </Link>
                  )}
                  <div className="sidebar-channel__search-wrap">
                    <input
                      placeholder={"Search group"}
                      value={postsSearchQuery}
                      onChange={e => setPostsSearchQuery(e.target.value)}
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          props.setPostsQuery(postsSearchQuery);
                        }
                      }}
                      className="channel-search"
                    />
                    <SearchIcon />
                  </div>
                  <div className="joined-channels">
                    <p className="title">Group Channels</p>
                    <div className="joined-channels__scroll-area">
                      <ul>
                        {groupChannels &&
                          groupChannels.data.map(
                            (channel: any, index: number) => {
                              if (
                                (channel.status === "open" ||
                                  channel.membership_status === "member" ||
                                  isAdmin) &&
                                channel.status !== "archived"
                              ) {
                                return (
                                  <li
                                    key={index}
                                    onClick={() => viewChannel(channel.id)}
                                    className={
                                      selectedChannel === channel.id
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    #{channel.name}
                                  </li>
                                );
                              }
                            }
                          )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <button
              className={"btn btn-create-group"}
              onClick={() => {
                props.createGroup();
              }}
            >
              <PlusWithCircle />
              <span>Create a Group</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any, ownProps: ISidebarComponentProps) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const groups = Map(state.get("group", {})).toJS() as any;
  const uid: string = state.getIn(["authorize", "uid"]);
  return {
    uid,
    global,
    groups
  };
};

const mapDispatchToProps = (
  dispatch: any,
  ownProps: ISidebarComponentProps
) => {
  return {
    createGroup: () => {
      dispatch(push("/creategroup"));
    },
    goToChannel: (
      groupSlug: string,
      channelId: number,
      isSubdomain: boolean
    ) => {
      dispatch(
        push(
          isSubdomain
            ? `/channel/${channelId}`
            : `/channel/${groupSlug}/${channelId}`
        )
      );
    },
    setSidebarVisibility: (status: boolean) =>
      dispatch(globalActions.setSidebarVisibility(status)),
    setPostsQuery: (query: string) =>
      dispatch(globalActions.setPostsQuery(query)),
    setChannelSidebarStatus: (status: boolean) =>
      dispatch(globalActions.setChannelSidebar(status))
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarComponent as any) as any) as any;
