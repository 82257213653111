import { AxiosError, AxiosResponse } from "axios";
import { CommonConstants } from "../constants/common";
import store from "../store/configureStore";
import { globalActions } from "../store/actions";
import { toast, ToastType } from "react-toastify";
import Cookie from "js-cookie";

export const headerify = async (request: any) => {
  store.store.dispatch(globalActions.showTopLoading());

  let token: any = Cookie.get(CommonConstants.USER_KEY)
    ? Cookie.get(CommonConstants.USER_KEY)
    : null;

  if (token) {
    token = JSON.parse(token);
    token = token.token || "";
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: token ? `Bearer ${token}` : null
    }
  };
};

export const handleError = (error: AxiosError) => {
  store.store.dispatch(globalActions.hideTopLoading());
  if (!error || !error.response) {
    return;
  }
  switch (error.response.status) {
    case 401:
      return Promise.reject(error.response);
    case 404:
    case 403: {
      return Promise.reject(error.response);
    }
    default: {
      toast("Something went wrong, please try again.", {
        type: ToastType.ERROR,
        autoClose: 1000
      });
      return;
    }
  }
  return Promise.reject(error.response);
};

export const handleResponse = (response: AxiosResponse) => {
  store.store.dispatch(globalActions.hideTopLoading());
  return response.data;
};

export const createQueryString = (object: any) => {
  if (!object || Array.isArray(object) || typeof object === "string") {
    return Promise.reject({ error: "Something went wrong" });
  }
  let str = "";
  Object.keys(object).forEach(
    (key, i) =>
      (str = `${str}${key}=${object[key]}${
        i < Object.keys(object).length - 1 ? "&" : ""
      }`)
  );
  return str;
};

export const createDynamicRouteRequest = (
  httpClient: any,
  getRouteFn: any,
  staticConfig = {}
) => (getRouteArgument: any, { body = {}, dynamicConfig = {} } = {}) => {
  const route = getRouteFn(getRouteArgument);
  const config = {
    ...staticConfig,
    ...dynamicConfig
  };
  return body ? httpClient(route, body, config) : httpClient(route, config);
};

export const createSimpleRequest = (
  httpClient: any,
  route: string,
  staticConfig = {}
) => ({ body = {}, dynamicConfig = {} } = {}) => {
  const config = {
    ...staticConfig,
    ...dynamicConfig
  };
  return body ? httpClient(route, body, config) : httpClient(route, config);
};

export const createQueryRequest = (
  httpClient: any,
  route: string,
  staticConfig = {}
) => (params: any, dynamicConfig = {}) => {
  const config = {
    ...staticConfig,
    ...dynamicConfig
  };
  const query = createQueryString(params);
  const url = `${route}${query}`;
  return httpClient(url, config);
};
