import { GlobalActionType } from "../../constants/globalActionType";
import { getCookieValue, setCookieValue } from "../../utils/cookieStorage";

// const commonService: ICommonService = provider.get<ICommonService>(SocialProviderTypes.CommonService);

export const showMessage = (message: any) => {
  if (!message || message === "") {
    message = "Bad request";
  }
  return {
    type: GlobalActionType.SHOW_MESSAGE_GLOBAL,
    payload: message
  };
};

export const setCurrentViewOpt = (searchQuery: string) => {
  return (dispatch: any, getState: any) => {
    dispatch(setCurrentView(searchQuery));
  };
};

export const setChannelSidebar = (status: boolean) => {
  return {
    type: GlobalActionType.SET_CHANNEL_SIDEBAR_STATUS,
    payload: status
  };
};

export const setSidebarForChannel = (status: boolean) => {
  return {
    type: GlobalActionType.SET_SIDEBAR_FOR_CHANNEL,
    payload: status
  };
};

export const refreshSidebar = (channelId: any) => {
  return {
    type: GlobalActionType.REFRESH_SIDEBAR,
    payload: channelId
  };
};

export const setHeaderTitleOpt = (callerKey: string, payload: any) => {
  return (dispatch: any, getState: any) => {
    switch (callerKey) {
      case "profile":
        return {
          type: GlobalActionType.SHOW_MESSAGE_GLOBAL,
          payload: {}
        };
        const userName =
          getState().user.info && getState().user.info[payload]
            ? getState().user.info[payload].fullName
            : "";
        dispatch(setHeaderTitle(userName));
        break;
      default:
        break;
    }
  };
};

export const setCurrentView = (searchQuery: string) => {
  return {
    searchQuery,
    type: GlobalActionType.SET_CURRENT_VIEW
  };
};

export const setHeaderTitle = (text: string) => {
  return {
    type: GlobalActionType.SET_HEADER_TITLE,
    payload: text
  };
};

export const setPostsQuery = (searchQuery: string) => {
  return {
    searchQuery,
    type: GlobalActionType.SET_POSTS_QUERY
  };
};

export const showTopLoading = () => {
  return {
    type: GlobalActionType.SHOW_TOP_LOADING
  };
};

export const hideTopLoading = () => {
  return {
    type: GlobalActionType.HIDE_TOP_LOADING
  };
};

export const showMasterLoading = () => {
  return {
    type: GlobalActionType.SHOW_MASTER_LOADING
  };
};

export const hideMasterLoading = () => {
  return {
    type: GlobalActionType.HIDE_MASTER_LOADING
  };
};

export const toggleHamburgerMenu = () => {
  const sideBarStatus = getCookieValue("sidebar");

  if (typeof sideBarStatus === null) {
    setCookieValue("sidebar", false);
  } else {
    setCookieValue("sidebar", !sideBarStatus);
  }

  return {
    type: GlobalActionType.TOGGLE_HAMBURGER_MENU
  };
};
export const clearMessage = () => {
  return {
    type: GlobalActionType.SHOW_MESSAGE_GLOBAL,
    payload: {}
  };
};

export const setSidebarVisibility = (status: boolean) => {
  return {
    type: GlobalActionType.TOGGLE_HAMBURGER_MENU,
    payload: status
  };
};

export const showPublicGroupLogin = () => {
  return {
    type: GlobalActionType.SHOW_PUBLIC_GROUP_LOGIN
  };
};
export const showPublicGroupSignup = () => {
  return {
    type: GlobalActionType.SHOW_PUBLIC_GROUP_SIGNUP
  };
};
