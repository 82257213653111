const validator: any = {
  name: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: (value: any) => {
          return /[^A-Za-z0-9]+/.test(value);
        },
        message: "Name can't contain special characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  channel_name: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: (value: any) => {
          return /^[a-zA-Z0-9_]*$/.test(value);
        },
        message: "Name can't contain special symbols or white space"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  group_name: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  description: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  subdomain: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: (value: any) => {
          return /^[a-zA-Z0-9_]*$/.test(value);
        },
        message: "Name can't contain special characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  posting_permission: {
    rules: [],
    errors: [],
    valid: true,
    invalid: true,
    state: ""
  },
  status: {
    rules: [
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: true,
    invalid: true,
    state: ""
  },
  full_name: {
    rules: [
      {
        test: /[^a-zA-Z ]/g,
        message: "Name can't contain special characters"
      },
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: false,
    state: ""
  },
  lastname: {
    rules: [
      {
        test: /^.{0,2}$/,
        message: "Name must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: true,
    state: ""
  },
  email: {
    rules: [
      {
        test: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email is not valid",
        invertTest: true
      },
      {
        test: /^.{0,2}$/,
        message: "Email must be longer than two characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: true,
    state: ""
  },
  password: {
    rules: [
      {
        test: (value: any) => {
          return value.length >= 6;
        },
        message: "Password must not be shorter than 6 characters"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: true,
    state: ""
  },
  password2: {
    rules: [
      {
        matchPassword: (password2: string, password: string) => {
          return password2 === password;
        },
        message: "Password razlicit bajo moj"
      },
      {
        test: /^ *$/,
        message: "This field is required."
      }
    ],
    errors: [],
    valid: false,
    invalid: true,
    state: ""
  }
};

export default validator;
