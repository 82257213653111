import React from "react";
import ReactDOM from "react-dom";
import "reflect-metadata";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { ConnectedRouter } from "connected-react-router/immutable";
import MainComponent from "containers/main";
import configureStore from "store/configureStore";
// import {LocalizeProvider} from "react-localize-redux";
import "./groupApp";
import { Provider } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  hideProgressBar: true
});

ReactDOM.render(
  <Provider store={configureStore.store}>
    <ConnectedRouter history={configureStore.history}>
      <MainComponent />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("app") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
