import { environment as prod } from "./environment.prod";
import { environment as dev } from "./environment.dev";

const config = {
  // Add common config values here
  reservedSubdomains: ["group", "stg"]
};

const configEnv = process.env.NODE_ENV === "production" ? prod : dev;


const appConfig = {
  ...config,
  ...configEnv
};
export default appConfig;
