import { injectable } from "inversify";
import databaseInstance from "../../index";
import { IGroupService } from "../../../core/services/groups";
import { groupActions } from "../../../store/actions";
import store from "../../../store/configureStore";
import { Group } from "../../../core/models/groups";
import { Post } from "../../../core/models/posts";

@injectable()
export class GroupService implements IGroupService {
  private dbInstance: any = databaseInstance;

  private static instance: GroupService;

  constructor() {
    GroupService.instance = this;
  }

  public static getInstance() {
    if (!GroupService.instance) {
      GroupService.instance = new GroupService();
    }
    return GroupService.instance;
  }

  public createGroup: (
    userId: number,
    name: string,
    description: string,
    status: string,
    slug?: string
  ) => Promise<void> = (userId, name, description, status, slug) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/users/${userId}/groups`, {
          name,
          description,
          status,
          subdomain: slug
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updateGroup: (
    userId: number,
    groupId: number,
    name: string,
    description: string,
    status: string
  ) => Promise<void> = (userId, groupId, name, description, status) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/users/${userId}/groups/${groupId}`, {
          name,
          description,
          status
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public deleteGroup: (groupId: number) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`groups/${groupId}`).then((response: any) => {
        resolve(response);
      });
    });
  };

  public getGroup(groupSlug: any) {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`groups/${groupSlug}`).then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        }
      });
    });
  }

  public editGroup: (
    userId: number,
    groupId: number,
    description: string
  ) => Promise<void> = (userId, groupId, description) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/users/${userId}/groups/${groupId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getUserGroups: (userId: number) => Promise<void> = userId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(`/users/${userId}/groups`).then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        }
      });
    });
  };

  public getAllGroups: (
    searchQuery?: string
  ) => Promise<Group[]> = searchQuery => {
    return new Promise<Group[]>((resolve, reject) => {
      this.dbInstance
        .get(`/groups?search=${searchQuery || ""}`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        });
    });
  };

  public joinGroup: (userId: number, groupId: number) => Promise<any> = (
    userId,
    groupId
  ) => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance
        .post(`groups/${groupId}/membership-requests`, { user_id: userId })
        .then((response: any) => {
          if (userId) {
            // @ts-ignore
            store.store.dispatch(groupActions.dbGetUserGroups(userId));
          }
          resolve(response);
        });
    });
  };

  public leaveGroup: (userId: number, groupId: number) => Promise<void> = (
    userId,
    groupId
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/users/${userId}/groups/${groupId}`)
        .then((response: any) => {
          if (userId) {
            // @ts-ignore
            store.store.dispatch(groupActions.dbGetUserGroups(userId));
          }
          resolve(response);
        });
    });
  };

  public updateGroupCoverImage: (
    groupId: number,
    cover_image: any
  ) => Promise<void> = (groupId, coverImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/cover-image`, coverImage)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        });
    });
  };

  public updateGroupProfileImage: (
    groupId: number,
    profile_image: any
  ) => Promise<void> = (groupId, profileImage) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/profile-image`, profileImage)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response);
          }
        });
    });
  };

  public paginationQuery: (
    paginationQuery: string
  ) => Promise<any> = paginationQuery => {
    return new Promise<any>((resolve, reject) => {
      if (paginationQuery) {
        this.dbInstance.get(paginationQuery).then(
          (response: any) => {
            if (response && response.data) {
              response.data.data = response.data.data.map((post: Post) => {
                return new Post(post);
              });
              resolve(response.data);
            }
          },
          (error: any) => {
            resolve({ data: [] });
          }
        );
      } else {
        resolve({ data: [] });
      }
    });
  };

  public getGroupPosts: (
    groupId: number,
    limit?: number,
    page?: number
  ) => Promise<void> = (groupId, limit, page) => {
    return new Promise<void>((resolve, reject) => {
      let query = `/groups/${groupId}/posts?sort=updated_at:desc`;
      if (limit) {
        query += `&limit=${limit}`;
      }
      if (page) {
        query += `&page=${page}`;
      }
      this.dbInstance.get(query).then((response: any) => {
        if (response && response.data) {
          response.data.data = response.data.data.map((post: Post) => {
            return new Post(post);
          });
          resolve(response.data);
        }
      });
    });
  };

  public getGroupPost: (postId: number) => Promise<Post> = postId => {
    return new Promise<Post>((resolve, reject) => {
      this.dbInstance.get(`/posts/${postId}`).then(
        (response: any) => {
          if (response && response.data) {
            resolve(new Post(response.data));
          } else {
            reject(null);
          }
        },
        (e: any) => {
          if (e.data && e.data.data && e.data.data.group) {
            reject(e.data.data.group);
          } else {
            reject(e);
          }
        }
      );
    });
  };

  public createGroupPost: (
    groupId: number,
    channelId: number,
    content: string
  ) => Promise<void> = (groupId, channelId, content) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/channels/${channelId}/posts`, { content })
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        });
    });
  };

  public broadcastPost: (postId: any) => Promise<void> = postId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/posts/${postId}/broadcast`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public deleteGroupPost: (postId: any) => Promise<void> = postId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.delete(`/posts/${postId}`).then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        }
      });
    });
  };

  public getGroupMembers: (groupId: number, query?: string) => Promise<void> = (
    groupId,
    query = ""
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/memberships?search=${query}`)
        .then((response: any) => {
          if (response && response.data) {
            const groupMembers: any = response.data;

            groupMembers.data = groupMembers.data.map((member: any) => {
              let memberType = "";
              let memberStatus = "";

              if (member.approved) {
                memberType = "Member";
                memberStatus = "Active";
              }

              if (member.admin || member.owner) {
                memberType = "Admin";
              }

              if (member.blocked) {
                memberStatus = "Blocked";
              }

              if (!member.user.job_title) {
                member.user.job_title = "N/A";
              }

              if (!member.user.location) {
                member.user.location = "N/A";
              }

              member.user = {
                ...member.user,
                type: memberType,
                status: memberStatus
              };
              return member;
            });

            resolve(groupMembers);
          }
        });
    });
  };

  public getGroupMembershipRequests: (
    groupId: any
  ) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(`/groups/${groupId}/membership-requests`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response);
          }
        });
    });
  };

  public approveGroupMembership: (
    requestId: number
  ) => Promise<void> = requestId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`memberships`, { group_membership_request_id: requestId })
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        });
    });
  };

  public declineGroupMembership: (
    requestId: number
  ) => Promise<void> = requestId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/membership-requests/${requestId}`)
        .then((response: any) => {
          if (response && response.data) {
            resolve(response.data);
          }
        });
    });
  };

  public removeGroupMember: (
    groupId: number,
    membershipId: number
  ) => Promise<void> = (groupId, membershipId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/memberships/${membershipId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public changeMembershipBlockState: (
    groupId: number,
    membershipId: number,
    blockState: boolean
  ) => Promise<void> = (groupId, membershipId, blockState) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/memberships/${membershipId}`, {
          block: blockState
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getPostComments: (postId: number, limit?: number) => Promise<void> = (
    postId,
    limit
  ) => {
    let query = `/posts/${postId}/comments?sort=updated_at:desc`;
    if (limit) {
      query += `&limit=${limit}`;
    }
    return new Promise<void>((resolve, reject) => {
      this.dbInstance.get(query).then((response: any) => {
        if (response && response.data) {
          resolve(response.data);
        }
      });
    });
  };

  public createPostComment: (
    postId: number,
    comment: string,
    mentions: any
  ) => Promise<void> = (postId, comment, mentions) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/posts/${postId}/comments`, { comment, mentions })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public deletePostComment: (commentId: number) => Promise<any> = commentId => {
    return new Promise<any>((resolve, reject) => {
      this.dbInstance.delete(`/comments/${commentId}`).then((response: any) => {
        resolve(response);
      });
    });
  };

  public reactToPost: (postId: number) => Promise<void> = postId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/posts/${postId}/reactions`, { type: "like" })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public unreactToPost: (postId: number) => Promise<void> = postId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/posts/${postId}/reactions`, { data: { type: "like" } })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public changeGroupInviteLink: (
    groupId: number
  ) => Promise<void> = groupId => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/invite-link/`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public exportMembers: (
    groupId: number,
    groupMembershipIds: any
  ) => Promise<void> = (groupId, groupMembershipIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/memberships/export`, {
          group_membership_ids: groupMembershipIds
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };
}
