import React, { useEffect, useState } from "react";
import { ITopBarNotificationComponentProps } from "./ITopBarNotificationComponentProps";

import "./TopBarNotificationComponent.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import useComponentVisible from "../../../utils/visible/visible";
import { NotificationService } from "../../../api/services/notification";
import { Map } from "immutable";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import miscUtils from "../../../utils/miscUtils";
import ScrollArea from "react-scrollbar";
import { Link } from "react-router-dom";

const TopBarNotificationComponent: React.FC<
  ITopBarNotificationComponentProps
> = props => {
  const { uid } = props;
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);
  const [notifications, setNotifications] = useState<any>([]);

  useEffect(() => {
    userNotification();
  }, []);

  function userNotification() {
    NotificationService.getInstance()
      .getUnreadNotifications(uid, 1)
      .then((res: any) => {
        if (res) {
          setNotifications(res.data.data);
        }
      });
  }

  const notificationTemplate = (
    type: string,
    user: string,
    message: string,
    slug: string,
    group: string,
    post: number
  ) => {
    switch (type) {
      case "new_member_added":
        return (
          <>
            <b>{user}</b> {message} <Link to={`/group/${slug}`}>{group}</Link>
          </>
        );
      case "commented_on_post":
        return (
          <>
            <b>{user}</b> {message} <Link to={`/posts/${post}`}>Post</Link>
          </>
        );
      case "liked_post":
        return (
          <>
            <b>{user}</b> {message} <Link to={`/posts/${post}`}>Post</Link>
          </>
        );
      case "request_approved":
        return (
          <>
            {message} <Link to={`/group/${slug}`}>{group}</Link>
          </>
        );
      case "new_request_pending":
        return (
          <>
            <b>{user}</b> {message} <Link to={`/group/${slug}`}>{group}</Link>
          </>
        );
      case "group_member_posted":
        return (
          <>
            <b>{user}</b> {message} <Link to={`/group/${slug}`}>{group}</Link>
          </>
        );
    }
  };

  return (
    <div className="header-notifications dropdown-menu" ref={ref}>
      <button
        className="header-notifications__top-bar--button"
        type="button"
        onClick={() => setIsComponentVisible(true)}
      >
        <FontAwesomeIcon icon={faBell} />
        Notifications
      </button>
      {isComponentVisible && (
        <div className="header-notifications-wrap dropdown flex flex-direction-column">
          <ScrollArea
            speed={0.8}
            className="notification-js-area"
            contentClassName="sidebar-js-content"
          >
            <div className="header-notifications-box">
              {notifications && notifications.length > 0 ? (
                notifications.map((notification: any, index: number) => (
                  <div
                    className="header-notifications-item flex flex-align-center"
                    key={index}
                  >
                    <div className="header-notifications-item--image">
                      {notification.data.profile_image ? (
                        <img
                          alt={""}
                          className="profile-image"
                          src={notification.data.profile_image}
                        />
                      ) : (
                        <div
                          className="profile-image"
                          data-color={miscUtils.getColorInitial(
                            `${notification.data.actor.name ||
                              notification.data.actor.full_name}`
                          )}
                        >
                          {miscUtils.getColorInitial(
                            notification.data.actor.name ||
                              notification.data.actor.full_name
                          )}
                        </div>
                      )}
                    </div>
                    <div className="header-notifications-item--message">
                      <span className="header-notifications-item--message__content">
                        {notificationTemplate(
                          notification.data.actor.type,
                          notification.data.actor.name,
                          notification.data.action,
                          notification.data.actor.group_slug,
                          notification.data.actor.group_name,
                          notification.data.actor.post
                        )}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div>No notification to show</div>
              )}
            </div>
          </ScrollArea>
          {notifications && notifications.length > 0 && (
            <div className="header-notifications-footer flex flex-align-center flex-justify-center">
              <Link
                onClick={() => setIsComponentVisible(false)}
                to={{ pathname: "/notifications" }}
              >
                View more Notifications
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (
  state: Map<string, any>,
  ownProps: ITopBarNotificationComponentProps
) => {
  const uid: string = state.getIn(["authorize", "uid"]);
  const user: any = state.getIn(["authorize", "user"]);

  return {
    uid,
    user
  };
};

export default withRouter(connect(
  mapStateToProps,
  {}
)(TopBarNotificationComponent as any) as any) as any;
