import * as redux from "redux";
import thunk from "redux-thunk";
// import DevTools from './devTools';
import { createBrowserHistory } from "history";
import createSagaMiddleware, { END } from "redux-saga";
import { rootReducer } from "store/reducers";
import { fromJS } from "immutable";
// import {routerMiclearddleware, connectRouter} from 'connected-react-router/immutable';
// import {offline} from '@redux-offline/redux-offline';
// import defaultConfig from '@redux-offline/redux-offline/lib/defaults';
// replacing redux-offline defaults with immutable* counterparts;
// @ts-ignore
import { routerMiddleware } from "connected-react-router";

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();

// - Build the middleware for intercepting and dispatching navigation actions
const sagaMiddleware = createSagaMiddleware();
// - initial state
const initialState = {};

// const persistOptions = {};
// const persistCallback = () => {
//     // console.log('rehydration completed')
// };

// const offlineConfig = {
//     ...defaultConfig,
//     persist,
//     persistAutoRehydrate,
//     persistOptions,
//     persistCallback,
//     offlineStateLens
// };

// - Config and create store of redux
const store: redux.Store<any> = redux.createStore(
  rootReducer(history),
  fromJS(initialState),
  redux.compose(
    redux.applyMiddleware(thunk, routerMiddleware(history), sagaMiddleware)
  )
); // offline(offlineConfig)

export default {
  store,
  runSaga: sagaMiddleware.run,
  close: () => store.dispatch(END),
  history
};
