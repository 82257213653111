import { injectable } from "inversify";
import { User } from "../../../core/models/users";
import { LoginUser, RegisterUserResult } from "../../../core/models/authorize";
import { SocialError } from "../../../core/models/common";
import {
  removeCookieValue,
  setCookieValue
} from "../../../utils/cookieStorage";
import databaseInstance from "../../index";
import { IAuthorizeService } from "../../../core/services/authorize";
import { headerify } from "../../APIUtils";
import jwt from "jwt-decode";
import Cookie from "js-cookie";
import { CommonConstants } from "../../../constants/common";

@injectable()
export class AuthorizeService implements IAuthorizeService {
  // @ts-ignore
  public dbInstance: any = databaseInstance;
  private cb: any;
  private static instance: AuthorizeService;

  constructor() {
    AuthorizeService.instance = this;
  }

  public static getInstance() {
    if (!AuthorizeService.instance) {
      AuthorizeService.instance = new AuthorizeService();
    }
    return AuthorizeService.instance;
  }

  public login: (email: string, password: string) => Promise<LoginUser> = (
    email,
    password
  ) => {
    this.dbInstance.interceptors.request.use(headerify);
    return this.dbInstance
      .post("/login", { email, password })
      .then((result: any) => {
        const user: any = { ...result.user, ...result.data };
        if (user) {
          setCookieValue(CommonConstants.USER_KEY, user);
          setCookieValue("sidebar", true);
          this.cb(user);
          return new LoginUser(user.id);
        }
        throw new SocialError(
          "AuthorizeService/login",
          "User object is invalid!"
        );
      });
  };

  public async registerUser(user: User) {
    try {
      const payload = {
        full_name: user.full_name,
        email: user.email,
        password: user.password
      };
      const result = await databaseInstance.post("/users", payload);
      const createdUser: any = { ...result.data.user, ...result.data };
      if (createdUser) {
        setCookieValue(CommonConstants.USER_KEY, createdUser);
        this.cb(user);
        return new RegisterUserResult(createdUser.id);
      }
      throw new SocialError(
        "AuthorizeService/login",
        "User object is invalid!"
      );
    } catch (error) {
      throw new SocialError(error.status, error.data.errors);
    }
  }

  public async forgotPassword(email: string) {
    const payload = {
      email
    };
    const result = await databaseInstance.post("/password/email", payload);
    return result;
  }

  public resetPassword: (
    email: string,
    verification: string,
    password: string,
    passwordConfirmation: string
  ) => Promise<any> = (email, verification, password, passwordConfirmation) => {
    return this.dbInstance.post("/password/reset", {
      email,
      password,
      token: verification,
      password_confirmation: passwordConfirmation
    });
  };

  public onAuthStateChanged: (callBack: any) => void = callBack => {
    this.cb = callBack;
    let userData: any = Cookie.get(CommonConstants.USER_KEY);
    if (userData) {
      userData = JSON.parse(userData);

      const tokenData: any = jwt(userData.token);
      const now = new Date();
      if (tokenData.exp < now.getTime() / 1000) {
        callBack(null);
        return;
      }
      callBack(userData.user);
    } else {
      callBack(null);
    }
  };

  public logout() {
    removeCookieValue(CommonConstants.USER_KEY);
    this.cb(null);
  }
}
