import { localizeReducer } from "react-localize-redux";
import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router";
import { authorizeReducer } from "./authorize";
import { groupReducer } from "./group";
// import {connectRouter} from "connected-react-router";

// - Import reducers
// import { groupsReducer } from './authorize'
// import { circleReducer } from './circles'
// import { commentReducer } from './comments'
// import { globalReducer } from './global'
// import { imageGalleryReducer } from './imageGallery'
// import { notificationReducer } from './notifications'
// import { postReducer } from './posts'
// import { userReducer } from './users'
// import { voteReducer } from './votes'
// import { serverReducer } from './server'
// import { connectRouter } from 'connected-react-router/immutable'
import { globalReducer } from "./global";
import { AuthorizeActionType } from "../../constants/authorizeActionType";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();
// - Reducers
const appReducer = combineReducers({
  locale: localizeReducer,
  authorize: authorizeReducer,
  router: connectRouter(history),
  global: globalReducer,
  group: groupReducer
} as any);

export const rootReducer = (history: any) => (state: any, action: any) => {
  if (action.type === AuthorizeActionType.LOGOUT) {
    // tslint:disable-next-line:no-parameter-reassignment
    state = undefined;
  }

  return appReducer(state, action);
};
