import React from "react";
import { IButtonPropsComponent } from "./IButtonPropsComponent";

const Button = (props: IButtonPropsComponent) => {
  return (
    <>
      <button
        className={"btn btn-spec-lr " + props.classes}
        onClick={!props.isLoading ? props.onClick : () => {}}
      >
        {!props.isLoading ? props.label : <div>Processing...</div>}
      </button>
    </>
  );
};

export default Button;
