import React, { useState } from "react";
import { ILoginProps } from "./ILoginProps";
import { Link, withRouter } from "react-router-dom";
import Button from "../../elements/button";
import { Map } from "immutable";
import { ILoginComponentProps } from "../../containers/login/ILoginComponentProps";
import { authorizeActions, globalActions } from "../../store/actions";
import { connect } from "react-redux";
import { ILoginComponentState } from "../../containers/login/ILoginComponentState";
import useForm from "../../utils/validator/validator";

import "./Login.scss";

const Login: React.FC<ILoginProps> = (props: any) => {
  const { message, subtitle, extClass, noAccount, funcToClose } = props;

  const [formData, setFormData] = useState<ILoginComponentState>({
    email: "",
    password: ""
  });

  const { errors, handleSubmit } = useForm(handleForm, formData);

  function handleForm() {
    const { login } = props;

    const error = false;

    if (!error) {
      if (funcToClose) {
        funcToClose();
      }

      login!(formData.email, formData.password);
    }
  }

  return (
    <div
      className={
        extClass.length > 0
          ? `card--login--wrap flex flex-justify-center flex-direction-column flex-align-center ${extClass}`
          : "card--login--wrap flex flex-justify-center flex-direction-column flex-align-center"
      }
    >
      <h1>Welcome to GroupApp</h1>
      <p>{subtitle}</p>

      {message && message.message && (
        <div className="error-message flex flex-align-center flex-justify-center">
          <p>{message.message}</p>
        </div>
      )}

      <form
        onSubmit={e => e.preventDefault()}
        className={"flex flex-direction-column flex-align-center"}
      >
        <div
          className={errors && errors.email ? "form-group error" : "form-group"}
        >
          <div className="form-group-header flex flex-justify-space-between">
            <label>Email address</label>
            <span className="error-label">
              {errors.email && errors.email.errorComponent}
            </span>
          </div>
          <input
            onChange={e => setFormData({ ...formData, email: e.target.value })}
            name="email"
            type="email"
            aria-label="Email"
          />
        </div>
        <div
          className={
            errors && errors.password ? "form-group error" : "form-group"
          }
        >
          <div className="form-group-header flex flex-justify-space-between">
            <label>Password</label>
            <span className="error-label">
              {errors.password && errors.password.errorComponent}
            </span>
          </div>
          <input
            onChange={e =>
              setFormData({ ...formData, password: e.target.value })
            }
            name="password"
            type="password"
            aria-label="Password"
          />
        </div>
        <div className="link-group flex">
          <Link className="btn btn-link" to="/forgot-password">
            Forgot Password
          </Link>
        </div>
        <Button onClick={handleSubmit} label="Login" />
      </form>
      {noAccount && (
        <div className="modal-footer">
          Dont have account?{" "}
          <Link className="link__to-login" to="/signup">
            Sign up
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: Map<string, any>) => {
  const global = Map(state.get("global", {})).toJS() as any;
  const { message } = global;
  return {
    global,
    message
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: ILoginComponentProps) => {
  return {
    login: (email: string, password: string) => {
      dispatch(globalActions.showTopLoading());
      dispatch(authorizeActions.dbLogin(email, password));
    },
    logout: () => {
      dispatch(authorizeActions.dbLogout());
    }
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login as any) as any) as any;
