import { injectable } from "inversify";
import databaseInstance from "../../index";
import { Post } from "../../../core/models/posts";

@injectable()
export class ChannelService {
  private dbInstance: any = databaseInstance;

  private static instance: ChannelService;

  constructor() {
    ChannelService.instance = this;
  }

  public static getInstance() {
    if (!ChannelService.instance) {
      ChannelService.instance = new ChannelService();
    }
    return ChannelService.instance;
  }

  public inviteMembersToChannel: (
    channelId: number,
    groupId: number,
    membersIds: any
  ) => Promise<void> = (channelId, groupId, membersIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/channels/${channelId}/members`, {
          group_member_ids: membersIds
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public removeChannelMember: (
    groupId: number,
    channelId: number,
    membersIds: any
  ) => Promise<void> = (groupId, channelId, membersIds) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`groups/${groupId}/channels/${channelId}/members`, {
          data: {
            channel_member_ids: membersIds
          }
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getChannelMembers: (
    channelId: number,
    groupId: number,
    searchQuery: string
  ) => Promise<void> = (channelId, groupId, searchQuery = "") => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/channels/${channelId}/members?q=${searchQuery}`
        )
        .then((response: any) => {
          if (response && response.data) {
            const groupMembers: any = response.data;

            groupMembers.data = groupMembers.data.map((member: any) => {
              let memberType = "";
              let memberStatus = "";

              if (member.approved) {
                memberType = "Member";
                memberStatus = "Active";
              }

              if (member.admin || member.owner) {
                memberType = "Admin";
              }

              if (member.blocked) {
                memberStatus = "Blocked";
              }

              if (!member.job_title) {
                member.job_title = "N/A";
              }

              if (!member.location) {
                member.location = "N/A";
              }

              member.user = member;
              member.user = {
                ...member.user,
                type: memberType,
                status: memberStatus
              };
              return member;
            });

            resolve(groupMembers);
          }
        });
    });
  };

  public leaveGroupChannel: (
    userId: number,
    channelId: number,
    groupId: number
  ) => Promise<void> = (userId, channelId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/users/${userId}/groups/${groupId}/channels/${channelId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public joinOpenChannel: (
    userId: number,
    channelId: number,
    groupId: number
  ) => Promise<void> = (userId, channelId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/users/${userId}/groups/${groupId}/channels`, {
          channel_id: channelId
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public createChannel: (
    groupId: number,
    name: string,
    description: string,
    status: string,
    posting_permission: string
  ) => Promise<void> = (
    postId,
    name,
    description,
    status,
    posting_permission
  ) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${postId}/channels`, {
          name,
          description,
          status,
          posting_permission
        })
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public filterArchivedChannels(channels: any) {
    channels.data = channels.data.map((channel: any) => {
      let channelStatus;

      if (channel.deleted_at) {
        channelStatus = "archived";
      }

      channel = {
        ...channel,
        status: channelStatus || channel.status
      };
      return channel;
    });
    return channels;
  }

  public getUserChannels: (
    userId: number,
    groupId: number,
    query?: string
  ) => Promise<void> = (userId, groupId, query) => {
    if (!query) {
      query = "";
    }

    return new Promise<void>((resolve, reject) => {
      if (groupId && userId) {
        this.dbInstance
          .get(`/users/${userId}/groups/${groupId}/channels?search=${query}`)
          .then((response: any) => {
            if (response && response.data) {
              let groupChannels: any = response.data;
              groupChannels = this.filterArchivedChannels(groupChannels);
              resolve(groupChannels);
            }
          });
      }
    });
  };

  public getGroupChannels: (
    groupId: number,
    query?: string
  ) => Promise<void> = (groupId, query) => {
    if (!query) {
      query = "";
    }
    return new Promise<void>((resolve, reject) => {
      if (groupId) {
        this.dbInstance
          .get(`/groups/${groupId}/channels?limit=100&search=${query}`)
          .then((response: any) => {
            if (response && response.data) {
              let groupChannels: any = response.data;
              groupChannels = this.filterArchivedChannels(groupChannels);
              resolve(groupChannels);
            }
          });
      }
    });
  };

  public getGroupAndUserChannels(
    groupId: number,
    userId?: number,
    query?: string
  ) {
    const channelsPromise = [];
    channelsPromise.push(this.getGroupChannels(groupId, query));
    if (userId) {
      channelsPromise.push(this.getUserChannels(userId, groupId, query));
    }
    return Promise.all(channelsPromise).then((response: any) => {
      let channels: any = { data: [] };
      if (response[1]) {
        channels.data = [...response[0].data, ...response[1].data];
      } else {
        channels.data = response[0].data;
      }

      channels.data = channels.data.reduce((unique: any, o: any) => {
        if (!unique.some((obj: any) => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
      channels = this.filterArchivedChannels(channels);
      return channels;
    });
  }

  public archiveGroupChannel: (
    groupId: number,
    channelId: number
  ) => Promise<void> = (groupId, channelId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .post(`/groups/${groupId}/channels/${channelId}/archives`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public unarchiveGroupChannel: (
    groupId: number,
    channelId: number
  ) => Promise<void> = (groupId, channelId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/channels/${channelId}/archives`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public deleteGroupChannel: (
    channelId: number,
    groupId: number
  ) => Promise<void> = (channelId, groupId) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .delete(`/groups/${groupId}/channels/${channelId}`)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public updateGroupChannel: (
    channelId: number,
    groupId: number,
    payload: any
  ) => Promise<void> = (groupId, channelId, payload) => {
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .put(`/groups/${groupId}/channels/${channelId}`, payload)
        .then((response: any) => {
          resolve(response);
        });
    });
  };

  public getChannelPosts: (
    groupId: number,
    channelId: number,
    query: any
  ) => Promise<void> = (groupId, channelId, query) => {
    const { search } = query;
    return new Promise<void>((resolve, reject) => {
      this.dbInstance
        .get(
          `/groups/${groupId}/channels/${channelId}/posts?sort=updated_at:desc&search=${
            search ? search : ""
          }`
        )
        .then((response: any) => {
          if (response && response.data) {
            response.data.data = response.data.data.map((post: Post) => {
              return new Post(post);
            });
            resolve(response.data);
          }
        });
    });
  };
}
